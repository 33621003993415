<template>
  <div style="height: 100vh!important;">

    <div style="margin-top: 35vh!important;">
      <div class="text-center w3-center justify-center" style="margin: 0 auto!important; ">

        <v-progress-circular size="40" indeterminate color="#AB005E"></v-progress-circular>

      </div>
    </div>



  </div>


</template>

<script>


export default {
  name: "home",

  async created(){
      window.location.href='https://businessportal.1app.online/'
  }

}
</script>

